import React from "react"
import { navigate } from "gatsby"
import { Pagination } from "@material-ui/lab"
import kebabCase from 'lodash/kebabCase'

const TagPagenation = ({ pageContext }) => {
  const { tag, numberOfPages, humanPageNumber } = pageContext

  const handleChange = (_event, value) => {
    value === 1 ? navigate(`/tags/${kebabCase(tag)}/`) : navigate(`/tags/${kebabCase(tag)}/${value}`)
  }
  return (
    <div className="page-numbers">
      <Pagination
        variant="outlined"
        defaultPage={humanPageNumber}
        count={numberOfPages}
        onChange={handleChange}
      />
    </div>
  )
}

export default TagPagenation
